<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
        <md-content style="width: 900px;">
            <div id="view-order-vehicle" class="page-content">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><span style="padding-left: 10px;">LỆNH ĐIỀU XE</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <div class="page-list-action"> 
                            <md-button @click="print()" class="md-raised md-primary">Print</md-button>
                            <md-button @click="close()" class="md-raised">Đóng</md-button>
                        </div>
                    </div>
                </div>
                <div style="padding: 10px;">
                    <div id="print-view" style="display: flex; flex-direction: column">
                        <div style="display: flex; justify-content: space-between;">
                            <div style="border-bottom: 1px solid #999; 
                                padding: 10px 0;
                                width: 25%; display: flex;align-items: center; justify-content: center;
                                border-top: 1px solid #999;border-left: 1px solid #999;">
                                <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                            </div>
                            <div style="border-bottom: 1px solid #999;
                                padding: 10px 0;
                                border-left: 1px solid #999;
                                border-top: 1px solid #999;
                                border-right: 1px solid #999;
                                width: 45%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-transform: uppercase;
                                font-weight: bold;">
                                <div><h4 style="text-align: center;">Lệnh điều động và quyết toán chuyến hàng</h4></div>
                            </div>
                            <div style="padding: 10px 0; border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                <div><span style="font-weight: bold;">Số: </span><span>{{ entity.jobCode }}</span></div>
                                <div><span style="font-weight: bold;">Ngày: </span><span>{{ entity.submitDate }}</span></div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; padding: 10px;">
                            <div style="font-weight: bold;">THÔNG TIN ĐIỀU ĐỘNG</div>
                            <div style="display: flex; flex-direction: column;">
                                <div style="display: flex; width: 100%;">
                                    <div style="width: 50%;">
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Ngày yêu cầu:</span>
                                            <span>{{ entity.submitDate }}</span>
                                        </div>
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Ngày thực hiện:</span>
                                            <span>{{ entity.jobDate }}</span>
                                        </div>
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Số xe:</span>
                                            <span v-if="entity.vehicle !== null">{{ entity.vehicle.licensePlate }}</span>
                                        </div>
                                    </div>
                                    <div style="width: 50%;">
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Lái xe:</span>
                                            <span>{{ entity.driver.fullName }}</span>
                                        </div>
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Số điện thoại:</span>
                                            <span>{{ entity.driver.phone }}</span>
                                        </div>
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Số mooc:</span>
                                            <span v-if="entity.mooc !== null">{{ entity.mooc.licensePlate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; width: 100%;">
                                    <div style="width: 100%;">
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 20%;">Ghi chú:</span>
                                            <span style="font-weight: bold;">{{ entity.note }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-cont" v-for="(item, index) in entity.details" :key="'cont-' + index" style="overflow: auto; border: 1px solid #999; margin-bottom: 10px">
                            <div style="display: flex; flex-direction: column; padding: 10px; ">
                                <div style="font-weight: bold;">THÔNG TIN HÀNG HÓA - {{ index + 1 }}</div>
                                <div style="display: flex;">
                                    <div style="width: 45%;">
                                        <div style="display: flex; padding-bottom: 5px;">
                                            <span style="width: 33%;">Khách hàng:</span>
                                            <span>{{ item.orderDetail.order.customer.companyAlias }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Loại hàng:</span>
                                            <span>{{ getTypeOfTransport(item.orderDetail.typeOfTransport) }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Mặt hàng:</span>
                                            <span>{{ item.orderDetail.goods.goodsName }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Khối lượng(tấn):</span>
                                            <span>{{ item.orderDetail.kg /1000 }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Số Bill/Booking:</span>
                                            <span>{{ item.orderDetail.order.booking.noBooking }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Số cont:</span>
                                            <span style="font-weight: bold;">{{ item.orderDetail.serial }}</span>
                                        </div>
                                    </div>
                                    <div style="width: 55%;">
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Loại cont:</span>
                                            <span style="font-weight: bold;">{{ item.orderDetail.unit.unitName }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Số seal:</span>
                                            <span>{{ item.orderDetail.seal }}</span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Closing time:</span>
                                            <span v-if="item.orderDetail.order.booking.cutoffTime !== ''">
                                                {{ item.orderDetail.order.booking.cutoffTime }}
                                            </span>
                                            <span v-if="item.orderDetail.order.booking.cutoffDate !== ''">
                                                {{ item.orderDetail.order.booking.cutoffDate }}
                                            </span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Nơi nhận cont:</span>
                                            <span v-if="item.orderDetail.typeOfTransport == 1 && item.orderDetail.transitDeliveryId > 0" style="font-weight: bold;">
                                                <span v-if="item.orderDetail.transitDelivery">{{ item.orderDetail.transitDelivery.locationName }}</span>
                                            </span>
                                            <span v-else-if="item.orderDetail.typeOfTransport == 2 && item.orderDetail.transitReceiptId > 0" style="font-weight: bold;">
                                                <span v-if="item.orderDetail.transitReceipt">{{ item.orderDetail.transitReceipt.locationName }}</span>
                                            </span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Nơi đóng/rút hàng:</span>
                                            <span v-if="item.orderDetail.typeOfTransport == 1 && item.orderDetail.transitDeliveryId > 0">
                                                <span v-if="item.orderDetail.placeOfReceiptGoods">{{ item.orderDetail.placeOfReceiptGoods.locationName }}</span>
                                            </span>
                                            <span v-else-if="item.orderDetail.typeOfTransport == 2 && item.orderDetail.transitReceiptId > 0">
                                                <span v-if="item.orderDetail.placeOfDeliveryGoods">{{ item.orderDetail.placeOfDeliveryGoods.locationName }}</span>
                                            </span>
                                        </div>
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 33%;">Nơi trả cont:</span>
                                            <span v-if="item.orderDetail.typeOfTransport == 1 && item.orderDetail.transitDeliveryId > 0" style="font-weight: bold;">
                                                <span v-if="item.orderDetail.placeOfReceiptCont"> {{ item.orderDetail.placeOfReceiptCont.locationName }}</span>
                                            </span>
                                            <span v-else-if="item.orderDetail.typeOfTransport == 2 && item.orderDetail.transitReceiptId > 0" style="font-weight: bold;">
                                                <span v-if="item.orderDetail.placeOfDeliveryCont">{{ item.orderDetail.placeOfDeliveryCont.locationName }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div style="width: 100%;">
                                        <div style="display: flex;padding-bottom: 5px;">
                                            <span style="width: 126px;">Điều xe:</span>
                                            <span style="font-weight: bold; color: red;">
                                                <span v-if="item.jobType == 1">Chở tròn tua</span>
                                                <span v-else-if="item.jobType == 2">Hạ cont tại kho</span>
                                                <span v-else-if="item.jobType == 3">Căt mooc tại kho</span>
                                                <span v-else-if="item.jobType == 4">Trả rỗng</span>
                                                <span v-else-if="item.jobType == 5">Trả hàng</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; flex-direction: column;padding: 10px;">
                                <div style="font-weight: bold;">THÔNG TIN THANH TOÁN</div>
                                <table style="height: auto; min-height: 100px !important; border-collapse: collapse;border-spacing: 0;">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px; border: 1px solid #e0e0e0; padding: 7px 10px;">Stt</th>
                                            <th style="width: 200px; border: 1px solid #e0e0e0; padding: 7px 10px;">Chi phí</th>
                                            <th style="width: 130px; border: 1px solid #e0e0e0; padding: 7px 10px;">Dự toán</th>
                                            <th style="width: 130px; border: 1px solid #e0e0e0; padding: 7px 10px;">Giải chi</th>
                                            <th style="width: 130px; border: 1px solid #e0e0e0; padding: 7px 10px;">Hóa đơn</th>
                                            <th style="width: 100px; border: 1px solid #e0e0e0; padding: 7px 10px;">Ngày hóa đơn</th>
                                            <th style="width: 160px; border: 1px solid #e0e0e0; padding: 7px 10px;">Ghi chú</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in item.estimate.details" :key="'est-detail-' + index">
                                            <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">{{ index + 1 }}</td>
                                            <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">{{ item.fee.feeName }}</td>
                                            <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{ formatNumber(item.estimateAmount) }}</td>
                                            <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                            <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                            <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                            <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">{{ item.estimateNote }}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style="font-weight: bold;">Tổng dự chi lái xe</td>
                                            <td style="text-align: right; font-weight: bold; padding-bottom: 5px;">
                                                {{ formatNumber(item.estimate.estimateFee + item.estimate.estimateOnBehalf) }}
                                            </td>
                                            <td colspan="4"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column;padding: 10px 0;">
                            <div style="font-weight: bold;">THÔNG TIN HÀNH TRÌNH DỰ KIẾN</div>
                            <table style="height: auto; width: 875px; white-space: normal; overflow: hidden;min-height: 100px !important; border-collapse: collapse;border-spacing: 0;">
                                <thead>
                                    <tr>
                                        <th style="width: 100px; border: 1px solid #e0e0e0; padding: 7px 10px;">Hành trình</th>
                                        <th style="width: 250px; border: 1px solid #e0e0e0; padding: 7px 10px;">Điểm đi</th>
                                        <th style="width: 250px; border: 1px solid #e0e0e0; padding: 7px 10px;">Điểm đến</th>
                                        <th style="width: 100px; border: 1px solid #e0e0e0; padding: 7px 10px;">Km</th>
                                        <th style="width: 100px; border: 1px solid #e0e0e0; padding: 7px 10px;">Định mức dầu</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in entity.routes" :key="'route-' + item.id">
                                        <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px; width: 120px;">{{ (index + 1) }}</td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px; width: 200px;">
                                            {{ item.start.locationName }}
                                        </td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px; width: 200px;">
                                            {{ item.end.locationName }}
                                        </td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px; width: 120px;">
                                            {{ item.km }}
                                        </td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px; width: 130px;">
                                            {{ item.fuel }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style="display: flex; flex-direction: column;padding: 10px 0;">
                            <div style="font-weight: bold;">THÔNG TIN HÀNH TRÌNH THỰC TẾ</div>
                            <table style="height: auto; min-height: 100px !important; border-collapse: collapse;border-spacing: 0;">
                                <thead>
                                    <tr>
                                        <th style="width: 120px; border: 1px solid #e0e0e0; padding: 7px 10px;">Hành trình</th>
                                        <th style="width: 250px; border: 1px solid #e0e0e0; padding: 7px 10px;">Điểm đi</th>
                                        <th style="width: 250px; border: 1px solid #e0e0e0; padding: 7px 10px;">Điểm đến</th>
                                        <th style="width: 140px; border: 1px solid #e0e0e0; padding: 7px 10px;">Km</th>
                                        <th style="width: 140px; border: 1px solid #e0e0e0; padding: 7px 10px;">Định mức dầu</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">&nbsp;</td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">&nbsp;</td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">&nbsp;</td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">&nbsp;</td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;">&nbsp;</td>
                                        <td style="border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                        <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                            
                        <div style="display: flex; justify-content: space-between; padding: 20px 0">
                            <div style="width: 33.33%; text-align: center;">
                                <div style="padding-bottom: 50px; font-weight: bold;">ĐIỀU ĐỘNG</div>
                                <div style="font-weight: bold;" v-if="entity.staff">{{ entity.staff.fullName }}</div>
                            </div>
                            <div style="width: 33.33%; text-align: center;">
                                <div style="padding-bottom: 50px; font-weight: bold;">LÁI XE</div>
                                <div style="font-weight: bold;" v-if="entity.driver">{{ entity.driver.fullName }}</div>
                            </div>
                            <div style="width: 33.33%; text-align: center;">
                                <div style="padding-bottom: 50px; font-weight: bold;">DUYỆT CHI</div>
                                <div style="font-weight: bold;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import jobService from "../../../api/jobService";
import messageBox from '../../../utils/messageBox';
import { mapActions } from 'vuex';
import common from '../../../mixins';

export default ({
    data() {
        return {
            loading: false,
            showDialog: false,
            id: 0,
            typeList: common.typeOfTransport,
            entity: { id: 0, driver: { fullName: '' }, vehicle: { licensePlate: '' },  mooc: { licensePlate: '' }}
        }
    },
    created(){
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        print () {
            this.$htmlToPaper('print-view');
        },

        open(id){
            this.id = id;
            this.getById();
            this.showDialog = true;
        },

        close(){
            this.showDialog = false;
        },

        getById(){
            this.setLoading(true);
            jobService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getTypeOfTransport(id){
            return this.typeList.filter((obj) => obj.id == id)[0].text;
        },
    }
})
</script>

<style >
    #view-order-vehicle {
        height: 95vh;
        overflow: auto;
    }
</style>