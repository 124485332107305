<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Báo cáo theo chuyến</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'e']" @click="exportExcel()" class="md-raised md-primary btn-add">
                            Export<md-tooltip>Xuất excel</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="jobName" @md-selected="getJobSelected" :md-options="jobs" @md-changed="getjobs"  @md-opened="getjobs">
                                    <label>Lệnh điều xe</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.jobCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openJob()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lệnh điều xe</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div style="overflow: auto; width: 100%;">
                        <table class="data-table" style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width:50px;">#</th>
                                    <th rowspan="2" style="width:200px;">Mã điều xe</th>
                                    <th rowspan="2" style="width:130px;">Ngày thực hiện</th>
                                    <th rowspan="2" style="width:100px;">Lái xe</th>
                                    <th rowspan="2" style="width:100px;">Biển số</th>
                                    <th colspan="3" style="width:360px; background-color: #88D66C;">Tổng hợp</th>
                                    <th colspan="7" style="width:360px; background-color: #FFD18E;">Chi phí</th>
                                    <th colspan="2" style="width:240px; background-color: #9CDBA6;">Doanh thu</th>
                                    <th rowspan="2" style="width:120px; background-color: #F7E7DC;">Chi hộ</th>
                                </tr>
                                <tr>
                                    <th style="width:120px; background-color: #88D66C;">Tổng chi phí</th>
                                    <th style="width:120px; background-color: #88D66C;">Tổng doanh thu</th>
                                    <th style="width:120px; background-color: #88D66C;">Lợi nhuận</th>
                                    <th style="width:120px; background-color: #FFD18E;">Lương chuyến</th>
                                    <th style="width:120px; background-color: #FFD18E;">Chi phí</th>
                                    <th style="width:120px; background-color: #FFD18E;">VETC</th>
                                    <th style="width:120px; background-color: #FFD18E;">Chi phí cố định</th>
                                    <th style="width:120px; background-color: #FFD18E;">Số Km</th>
                                    <th style="width:120px; background-color: #FFD18E;">Dầu(Lít)</th>
                                    <th style="width:120px; background-color: #FFD18E;">Chi phí dầu</th>

                                    <th style="width:120px; background-color: #9CDBA6;">Phí dịch vụ</th>
                                    <th style="width:120px; background-color: #9CDBA6;">Cước vận chuyển</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left"><a @click="viewJob(item.jobId)">{{  item.jobCode }}</a></td>
                                    <td class="center">{{ item.jobDate }}</td>
                                    <td class="left">{{ item.driver }}</td>
                                    <td class="center">{{ item.licensePlate }}</td>
                                    <td class="right">
                                        <span style="color: red; font-weight: bold;">{{ formatNumber(item.totalExpense) }}</span>
                                    </td>
                                    <td class="right">
                                        <span style="color: #059212; font-weight: bold;">{{ formatNumber(item.totalRevenue) }}</span>
                                    </td>
                                    <td class="right">
                                        <span v-if="item.totalProfit > 0" style="color: #3572EF; font-weight: bold;">{{ formatNumber(item.totalProfit) }}</span>
                                        <span v-else style="color: red; font-weight: bold;">{{ formatNumber(item.totalProfit) }}</span>
                                    </td>
                                    <td class="right">{{ formatNumber(item.paySalary) }}</td>
                                    <td class="right">{{ formatNumber(item.payFee) }}</td>
                                    <td class="right">{{ formatNumber(item.payVetc) }}</td>
                                    <td class="right">{{ formatNumber(item.fixedCost) }}</td>
                                    <td class="right">{{ formatNumber(item.km) }}</td>
                                    <td class="right">{{ formatFloat(item.fuel) }}</td>
                                    <td class="right">{{ formatNumber(item.amountFuel) }}</td>
                                    <td class="right">{{ formatNumber(item.payService) }}</td>
                                    <td class="right">{{ formatNumber(item.transportCost) }}</td>
                                    <td class="right">{{ formatNumber(item.payOnBehalf) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <jobList ref="jobList" @close="closeJob"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <orderList ref="orderList" @close="closeOrder"/>
        <viewJobForm ref="viewJobForm" />
    </div>
</template>
<script>
    import reportLogisticService from '../../../api/reportLogisticService';
    import companyService from '../../../api/companyService';
    import branchService from '../../../api/branchService';
    import orderService from '../../../api/orderService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import jobList from '../../../components/popup/_JobList.vue';
    import jobService from '../../../api/jobService';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import orderList from '../../../components/popup/_OrderList.vue';
    import viewJobForm from '../../job-manage/order-vehicle/View.vue';
    import { APP_CONFIG } from '@/utils/constants';

    export default {
        components: {
            jobList,
            customerList,
            orderList,
            viewJobForm
        },
        metaInfo: {
            title: 'Báo cáo theo chuyến'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, fromDate: this.getRangeDate(-7), toDate: common.dateNow, branchId: 0, customerId: 0, orderId: 0, jobId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                customerType: common.companyType.customer,
                id: 0,
                jobName: '',
                jobs: [],
                branchs: [],
                branchId: 0,
                customerName: '',
                customers: [],
                orderName: '',
                orders: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/report-logistic/export-report-by-job?fromDate=" + 
                this.search.fromDate + "&toDate=" + this.search.toDate + "&branchId=" + this.search.branchId
                + "&customerId=" + this.search.customerId
                + "&orderId=" + this.search.orderId
                + "&jobId=" + this.search.jobId;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            viewJob(jobId){
                this.$refs.viewJobForm.open(jobId);
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, branchId: 0, fromDate: this.getRangeDate(-7), toDate: common.dateNow, jobId: 0 };
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/report-job?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportLogisticService.getReportJob(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            //Job
            closeJob(item){
                this.jobName = item.fullName;
                this.search.jobId = item.id;
                this.$refs.jobList.close();
            },

            getJobSelected(val){
                this.search.jobId = val.id;
                this.jobName = val.fullName;
            },

            openJob(){
                this.$refs.jobList.open();
            },

            getjobs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, branchId: this.branchId, keyword:  searchTerm };
                jobService.getJobs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.jobs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Order
            closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            jobName: function (val) { 
                if(val == ''){
                    this.search.jobId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
